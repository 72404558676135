import { ArItem, ETripType, IBEPax, TripDetail } from "@hkexpressairwayslimited/ui";
import { SelectedArItems } from "modules/features/flightBooking/reducers";
import {
  OnSimCardCloseData,
  OnSimCardSelectedData,
  OnSportSelectedData,
  OnUFirstCloseData,
  OnUFirstSelectedData,
  SportCloseEvent,
} from "./OthersSection";

export interface AddExtrasPageProps {
  tripDetail: TripDetail;
  passengers: IBEPax[];
  tripType: ETripType;
  onBaggageSelected: (data: OnBaggageSelectedData) => void;
  onBaggageConfirmed?: (b: boolean) => void;
  onMealSelected: (data: OnMealSelectedData) => void;
  onMealConfirmed?: (b: boolean, cleanAction?: boolean) => void;
  onMealClose?: (data: OnMealCloseData) => void;
  onSportSelected: (data: OnSportSelectedData) => void;
  onSportConfirmed?: (b: boolean, cleanAction?: boolean) => void;
  onSportClose?: SportCloseEvent;
  onUFirstConfirmed?: (b: boolean) => void;
  onUFirstSelected: (data: OnUFirstSelectedData) => void;
  onSimCardConfirmed?: (b: boolean) => void;
  onSimCardSelected?: (data: OnSimCardSelectedData) => void;
  onSimCardClose?: (data: OnSimCardCloseData) => void;
  totalCost?: number;
  noPreSelection?: boolean;
  selectedSportArItem: SelectedArItems[];
  selectedBaggageArItem: SelectedArItems[];
  selectedMealArItem: SelectedArItems[];
  selectedUFirstArItem: SelectedArItems[];
  selectedSimCardArItem: SelectedArItems[];
  baggageConfirmed: boolean;
  mealConfirmed: boolean;
  simConfirmed?: boolean;
  sportConfirmed?: boolean;
  uFirstConfirmed?: boolean;
  noBanner?: boolean;
  noTags?: boolean;
  onSubmit?: () => void;
  notTransferSSR?: any[];
  submitLabel?: JSX.Element;
  onOpenMealDrawer?: () => void;
  onOpenSportDrawer?: () => void;
  onOpenUFirstDrawer?: () => void;
  onUFirstCardClose?: (data: OnUFirstCloseData) => void;
  onOpenSimCardDrawer?: () => void;
  droppedSeat?: ArItem[];
  disableDiscountedBanner?: boolean;
}

export type OnBaggageSelectedData = {
  tripId: string;
  journeyKey: string;
  selectedBaggage: ArItem;
  paxId: string;
  active: boolean;
  renderOption: SelectableArItem[];
};

export type OnMealSelectedData = {
  tripId: string;
  selectedJourneyKey: string;
  selectedSegmentKey: string;
  paxId: string;
  mealCode: string;
  amount: number;
};

export type OnMealCloseData = {
  tripId: string;
  journeyKey: string;
  segmentKey: string;
};

export enum EArItemType {
  BAGGAGE = "BAGGAGE",
  INSURANCE = "INSURANCE",
  CARBON_OFFSET = "CARBON_OFFSET",
  MEAL = "MEAL",
  U_FIRST = "U_FIRST",
  SIM_CARD = "SIM_CARD",
  SPORT_EQUIPMENT = "SPORT_EQUIPMENT",
  SEAT = "SEAT",
  SMS = "SMS",
}
export const tripArItemTypes = [EArItemType.INSURANCE, EArItemType.CARBON_OFFSET, EArItemType.SIM_CARD];
export const journeyArItemTypes = [
  EArItemType.BAGGAGE,
  EArItemType.U_FIRST,
  EArItemType.SPORT_EQUIPMENT,
  EArItemType.SMS,
];
export const segmentArItemTypes = [EArItemType.MEAL, EArItemType.SEAT];
export const addExtrasPageArItemTypes = [
  EArItemType.MEAL,
  EArItemType.SIM_CARD,
  EArItemType.BAGGAGE,
  EArItemType.U_FIRST,
  EArItemType.SPORT_EQUIPMENT,
];
export const paymentPageArItemTypes = [EArItemType.CARBON_OFFSET, EArItemType.INSURANCE];

export enum EOtherArCode {
  SPORT_EQUIPMENT = "BULK",
  U_FIRST = "XPCI",
  SIM_CARD = "SIMA",
  SIM_CARD_B = "SIMB",
}

export enum EBaggageArCode {
  _0KG = "AC0",
}

export type SelectableArItem = ArItem & {
  selected?: boolean;
  id: string;
  purchased?: boolean;
  arMetaData?: { included?: boolean };
};

export type JourneyConf<D = {}> = {
  id: string;
  label?: string;
  selectAmount?: number;
  data?: D;
};

export type ArItemsAmount = {
  [K: string]: ArItemAmount;
};
export type ArItemAmount = {
  [K: string]: number;
};
